import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteVariety, updateSearch } from "../../../actions/varieties";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Configure } from "react-instantsearch-dom";
import VarietySearchBox from "../VarietySearchBox";
import VarietyLoadMore from "../VarietyLoadMore";
import { IndexLinkButton, PageHeader } from "../../../ui";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY
);

class VarietiesList extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange = (value, refine) => {
    this.props.updateSearch(value);
    refine(value);
  };
  render() {
    const { search } = this.props.varieties;
    return (
      <React.Fragment>
        <PageHeader title={"Varieties"} half={7}>
          <IndexLinkButton linkUrl="/varieties/new" />
        </PageHeader>
        <InstantSearch indexName={`Variety_${process.env.NODE_ENV}`} searchClient={searchClient}>
          <Configure hitsPerPage={25} />
          <VarietySearchBox
            defaultRefinement={search}
            handleChange={this.handleChange}
          />
          <VarietyLoadMore combine={false} />
        </InstantSearch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  varieties: state.varieties
});

export default connect(
  mapStateToProps,
  {
    deleteVariety,
    updateSearch
  }
)(VarietiesList);
