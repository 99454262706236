import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Configure } from "react-instantsearch-dom";
import { combineVariety, updateSearch } from "../../../actions/varieties";
import VarietySearchBox from "../VarietySearchBox";
import VarietyLoadMore from "../VarietyLoadMore";
import { View } from "./views";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY
);

class VarietyCombineEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      combined: false
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onSave = () => {
    const { variety } = this.props.varieties;
    this.setState({ combined: true }, () => {
      this.props.combineVariety(variety.id, this.state.checked);
    });
  };

  handleChange = (value, refine) => {
    this.props.updateSearch(value);
    refine(value);
  };

  handleToggle = value => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({ ...this.state, checked: newChecked });
  };

  render() {
    const { waiting, variety } = this.props.varieties;
    if (this.state.combined && !waiting) {
      return <Redirect push to={`/varieties/${variety.id}`} />;
    }
    const { checked } = this.state;
    const { search } = this.props.varieties;
    return (
      <View variety={variety} onSave={this.onSave}>
        <InstantSearch indexName={`Variety_${process.env.NODE_ENV}`} searchClient={searchClient}>
          <Configure hitsPerPage={25} />
          <VarietySearchBox
            defaultRefinement={search}
            handleChange={this.handleChange}
          />
          <VarietyLoadMore
            combine={true}
            handleToggle={this.handleToggle}
            checked={checked}
            variety={variety}
            experiementalVarieties={variety.experimentalVarieties || []}
          />
        </InstantSearch>
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    varieties: state.varieties
  };
};

export default connect(
  mapStateToProps,
  {
    combineVariety,
    updateSearch
  }
)(VarietyCombineEdit);
